import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../Services/modal.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private modalService: ModalService,

    private _router: Router) { }

  ngOnInit() {
  }

  smartOddsSubOptions() {
    this._router.navigate(['suboptions']);
  }

  smartOddsUserGuide() {
    this._router.navigate(['userguide']);

  }

  home() {
    this._router.navigate(['home']);
  }
}
