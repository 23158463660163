import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '../Services/modal.service';
import { User } from '../models/user';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { Product } from '../models/product';
import { ProductSubscription } from '../models/product-subscription';
import { UserSubscription } from '../models/user-subscriptions';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { SingleResponse } from '../models/single-response';
import { Guid } from 'guid-typescript';
import { DOCUMENT } from '@angular/common';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';



@Component({
  selector: 'app-sub-purchase',
  templateUrl: './sub-purchase.component.html',
  styleUrls: ['./sub-purchase.component.css']
})

export class SubPurchaseComponent implements OnInit {

  paymentType = "Bank";
  onCheckoutForm: FormGroup;
  inputs: any;
  products: Product[];
  selectedProduct: Product;
  selectedSub: ProductSubscription;
  notificationDisplayed = false;
  paypalNotificationDisplayed = false;
  payPalConfig: IPayPalConfig;
  paypalClicked = false;
  context: Context;

  constructor(fb: FormBuilder, private _router: Router, private modalService: ModalService, private _sharingService: SharingService<Context>,
    public _api: RacemasterApiService, @Inject(DOCUMENT) document,
  ) {
    this.onCheckoutForm = fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.required],
      telephoneno: ["", Validators.required],
      payment: ["", Validators.required]
    })
    
    this.context = this._sharingService.getMessage(); 

    this.currentUser = this.context.activeUser;
    this.products = this.context.products;
    this.selectedProduct = this.products.find(p => p.id === this.context.productId);
    this.selectedSub = this.selectedProduct.subscriptions.find(s => s.id === this.context.subscriptionId);
  }

  //we can't call active user directly here so we had to create current user and call current message to retrieve active user
  //and store it inside current user
  currentUser: User;

  ngOnInit() {
    this.inputs = this.modalService.getInputs();

    
    //Finding subscription id that is equal to the subscription recieved from the selected product

  }
  fillPaypalOrder(userSub: UserSubscription) {
    this.payPalConfig = {
      currency: 'AUD',
      clientId: 'AWU02421hb9lpRUCGVsInHkuJezNhRr9qApQncZkcSh7IFhqvPaHPLl_Khn5hM90roNZJdy-DgogEdVZ',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: "CAPTURE",
        application_context: {
          shipping_preference: "NO_SHIPPING",
          brand_name: 'racemaster.com.alocau'
        },
        purchase_units: [{
          amount: {
            currency_code: 'AUD',
            value: this.selectedSub.price.toString(),
            breakdown: {
              item_total: {
                currency_code: 'AUD',
                value: this.selectedSub.price.toString(),
              }
            }
          },
          items: [{
            name: "Subscription to: " + this.selectedSub.description + this.selectedSub.displayText,

            category: 'PHYSICAL_GOODS',
            unit_amount: {
              currency_code: 'AUD',
              value: this.selectedSub.price.toString(),
            },
            quantity: '1',
          }]
        }]
      },
      advanced: {
        commit: 'true',
      },
      onApprove: (data, actions) => {
        this.paypalNotificationDisplayed = true;

        return actions.order.get().then((details) => {
          userSub.paypalOrderId = details.id;
          userSub.paypalPayerId = details.payer_id;

          this._api.post(userSub, "UserSubscriptions").subscribe(
            (data: SingleResponse) => { },
            (error) => { alert(error.message) },
            () => {
            });
        });
      },
      onCancel: function (data) {
        // Show a cancel page, or return to cart  
        return;
      },
      onError: function (err) {
        // Show an error page here, when an error occurs  
        alert(err.message);
        return;
      },
    }
  }

  onCheckout() {
    var userSubscription = new UserSubscription();

    userSubscription.id = Guid.create().toString();
    userSubscription.userId = this.currentUser.id;
    userSubscription.subscriptionId = this.selectedSub.id;
    userSubscription.startDate = new Date(Date.now());
    if (this.paymentType === "Bank"){
      userSubscription.paymentType = 0;
    }
    else{
      userSubscription.paymentType = 1;
    }
    userSubscription.price = this.selectedSub.price;

    if (this.paymentType === "Paypal") {
      this.fillPaypalOrder(userSubscription);
      this.paypalClicked = true;
    }
    else {
      this._api.post<UserSubscription>(userSubscription, "UserSubscriptions").subscribe(
        (data: SingleResponse) => { },
        (error) => { alert(error.message) },
        () => {
          this.notificationDisplayed = true;
        })
    }

  }
  resetPaypal($event) {
    this.paypalClicked = false;
  }

  smartOddsSubOptionsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSuboptionsComponent, inputs, {});
  }
  smartOddsSubOptions() {
    this.smartOddsSubOptionsModal();
  }
  home() {
    this._router.navigate(['home']);
  }
  email() {
    this._router.navigate(['email']);
  }

}
