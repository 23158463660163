import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { SubPurchaseComponent } from '../sub-purchase/sub-purchase.component';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { ProductSubscription } from '../models/product-subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-racemaster-ratings',
  templateUrl: './racemaster-ratings.component.html',
  styleUrls: ['./racemaster-ratings.component.css']
})
export class RacemasterRatingsComponent implements OnInit {
  isLoggedIn = false;
  appContext: Context;
  threeMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  constructor(private modalService : ModalService, private _sharingService: SharingService<Context>, private _router : Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser !=null;

   }

  ngOnInit() {
    this.threeMonthsSub  = this.appContext.products.find(p => p.id === "C723BA71-A776-43C2-B6D9-5A9044476077").subscriptions.find(s => s.id === "DCCDD4F7-8DB4-448E-AC1D-F99C2A2EB1ED");
    this.twelveMonthsSub  = this.appContext.products.find(p => p.id === "C723BA71-A776-43C2-B6D9-5A9044476077").subscriptions.find(s => s.id === "9EF3FB5D-6A09-4629-97D0-E390F583DB13");
  }
  onSubmit(subId : string) {
    if(this.isLoggedIn){
      this.subPurchaseModal(subId);
    }
    else {
      // alert("Please sign in first in order to be able to subscribe")
      this._router.navigate(['signin']);
    }
  }

 

  subPurchaseModal(subId : string) {
    this.appContext.productId = "C723BA71-A776-43C2-B6D9-5A9044476077";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);  
  }

  home() {
    this._router.navigate(['home'])
  }
  
   subOptions() {
   this._router.navigate(['suboptions'])
    }
}
