import { ProductSubscription } from './product-subscription';
import { ProductSubscriptionOption } from './product-subscription-option';

export class Product {
    public id: string;
    public name: string;
    public description: string;
    public shortDescription: string;
    public downloadUrl: string;
    public imageUrl: string;
    public subscriptions: ProductSubscription[];
    public displayOrder: number;
    public productSubscriptionOptions: ProductSubscriptionOption[];
}
