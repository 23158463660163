import { Component, OnInit, Inject, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ModalService } from '../Services/modal.service';
import { ForgottenPasswordComponent } from '../forgotten-password/forgotten-password.component';
import { EmailComponent } from '../email/email.component';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { User } from '../models/user';
import { LoginResponse } from '../models/login-response';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { UserResponse } from '../models/user-response';
@Component({
  selector: 'app-enter-password',
  templateUrl: './enter-password.component.html',
  styleUrls: ['./enter-password.component.css']
})
export class EnterPasswordComponent implements OnInit {
  form: FormGroup;
  isPassShown = false;
  appContext: Context;
  invalidPass = false;

  constructor(
    @Inject(DOCUMENT) document,
    fb: FormBuilder,
    private _service: RacemasterApiService,
    private _router: Router,
    private _sharingService: SharingService<Context>,
    private modalService: ModalService,
    private _CookieService: CookieService,
    public _datepipe: DatePipe) {
    this.form = fb.group({
      password: ["", Validators.required]
    });
    this.appContext = this._sharingService.getMessage();
  }

  ngOnInit() {

  }
  home() {
    this._router.navigate(['home'])
  }
  onSubmit() {
    //filling password value in appcontext
    
    this.appContext.LoginRequest.password = this.form.controls["password"].value;
    this._service.login(this.appContext.LoginRequest).subscribe(
      (data: LoginResponse) => { this.appContext.loginInfo = data; },
      (err) => {
        this.invalidPass = true;
      },
      //UPON SUCCESSFUL Request
      () => {
        // we use checkemailexistance method here to get username(from userresponse model)to give welcome username message to the user
        this._service.checkEmailExistance(this.appContext.LoginRequest.email).subscribe(
          (data: UserResponse) => {
            //filling active user data after successful sign in
            this.appContext.activeUser = new User();
            this.appContext.activeUser.userName = data.userName;
            this.appContext.activeUser.userClaims = data.userClaims;
            this.appContext.activeUser.firstName = data.firstName;
            this.appContext.activeUser.lastName = data.lastName;
            this.appContext.activeUser.email = data.email;
            this.appContext.activeUser.id = data.id;
            this.appContext.activeUser.phoneNumber = data.phoneNumber;
            this._sharingService.setMessage(this.appContext);
          },
          (err) => { alert(err.message); },
          () => {
            this._CookieService.set("user_name", this.appContext.activeUser.userName, null, '', '', false, 'Lax');
            this._CookieService.set("last_name", this.appContext.activeUser.lastName, null, '', '', false, 'Lax');
            this._CookieService.set("first_name", this.appContext.activeUser.firstName, null, '', '', false, 'Lax');
            this._CookieService.set("phone_number", this.appContext.activeUser.phoneNumber, null, '', '', false, 'Lax');
            this._CookieService.set("email_address", this.appContext.activeUser.email, null, '', '', false, 'Lax');
            this._CookieService.set("user_id", this.appContext.activeUser.id, null, '', '', false, 'Lax');
            this._CookieService.set("user_token", this.appContext.loginInfo.userToken, null, '', '', false, 'Lax');
            this._CookieService.set("user_refresh_token", this.appContext.loginInfo.refreshToken, null, '', '', false, 'Lax');

            //We use string in cookie service as it only accepts
            this._CookieService.set("admin", String(this.appContext.activeUser.userClaims.find(c => c.claimName === "UserMode").claimValue === "Administrator"), null, '', '', false, 'Lax');
            this._CookieService.set("user_token_expire", this._datepipe.transform(this.appContext.loginInfo.expiresOn, "MMM/dd/yyyy hh:mm:ss"), null, '', '', false, 'Lax');
            this.subOptions();
          }
        )
      }
    )
  }
  togglePassword($event) {
    var imageTag = document.getElementById("togglePasswordImg");
    var inputTag = document.getElementById("password");
    this.isPassShown = !this.isPassShown;
    if (this.isPassShown) {
      // Case visible 
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag != null) {
        inputTag.setAttribute("type", "text");
      }
    }
    else {
      // Case Invisible
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag != null) {
        inputTag.setAttribute("type", "password");
      }
    }
  }
  forgottenPasswordModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(ForgottenPasswordComponent, inputs, {});
  }
  forgottenPassword() {
    this.close();
    this.forgottenPasswordModal();
  }
  public close() {
    this.modalService.destroy();
  }
  emailModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EmailComponent, inputs, {});
  }
  Email() {
   this._router.navigate(['email']);
  }
  subOptionsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSuboptionsComponent, inputs, {});
  }
  subOptions() {
    this._router.navigate(['suboptions'])
     }
}
