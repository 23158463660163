import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailComponent } from '../email/email.component';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  passMatch = true;
  isPassShown = false;
  isConfirmPassShown = false;
  showError = false;
  emptyInputs = false;
  constructor(fb: FormBuilder, private modalService: ModalService) {
    this.form = fb.group({
      password: ["", [Validators.required, Validators.maxLength(6)]],
      confirmpassword: ["", Validators.required]
    });
  }

  ngOnInit() {
    // we set show error = false to remove alerts when value changes
    this.form.controls["password"].valueChanges.subscribe(x => this.showError = false);
    this.form.controls["confirmpassword"].valueChanges.subscribe(x => this.showError = false);

  }
  togglePassword($event) {
    var imageTag = document.getElementById("togglePasswordImg");
    var inputTag1 = document.getElementById("password");
    this.isPassShown = !this.isPassShown;
    if (this.isPassShown) {
      // Case visible 
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "text");

      }
    }
    else {
      // Case Invisible
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "password");
      }
    }
  }
  toggelConfirmPassword($event) {
    var imageTag2 = document.getElementById("toggleConfirmPasswordImg");
    var inputTag2 = document.getElementById("confirmpassword");

    this.isConfirmPassShown = !this.isConfirmPassShown;
    if (this.isConfirmPassShown)
    // case visible
    {
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "text")
      }
    }
    else {
      // Case Invisible
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "password");
      }
    }
  }
  onUpdate() {
    //since show error must be true (in order for an error message to appear) in addition to either passMatch = false or emptyInputs = true, then we make it true either ways
    this.showError = true;

    if (this.form.controls["password"].value != this.form.controls["confirmpassword"].value) {
      this.passMatch = false;
      return;
    }
    else {
      this.passMatch = true;
    }

    if (this.form.controls["password"].value == "" || this.form.controls["confirmpassword"].value == "") {
      this.emptyInputs = true;
      return;
    }
    else {
      this.emptyInputs = false;
    }
    if (this.passMatch == true && this.emptyInputs == false) {
      this.signIn();
    }
  }
  signInModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(SignInComponent, inputs, {});
  }
  signIn() {
    this.close();
    this.signInModal();
  }
  public close() {
    this.modalService.destroy();
  }

  emailModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EmailComponent, inputs, {});
  }
  Email() {
    this.emailModal();
  }
}
