import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { AnalysisComponent } from '../analysis/analysis.component';

@Component({
  selector: 'app-anytime-windows',
  templateUrl: './anytime-windows.component.html',
  styleUrls: ['./anytime-windows.component.css']
})
export class AnytimeWindowsComponent implements OnInit {

  constructor(private modalService: ModalService) { }

  ngOnInit() {
  }
  public close() {
    this.modalService.destroy();
  }
  analysisModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(AnalysisComponent, inputs, {});
  }
  analysis() {
    this.close();
    this.analysisModal();
  }
  
}
