import { Component, OnInit } from '@angular/core';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { Router } from '@angular/router';
import { Product } from '../models/product';
import { CollectionResponse } from '../models/collection-response';


@Component({
  selector: 'app-racemaster-suboptions',
  templateUrl: './racemaster-suboptions.component.html',
  styleUrls: ['./racemaster-suboptions.component.css']
})

export class RacemasterSuboptionsComponent implements OnInit {

  appContext = new Context();

  constructor(private _apiService: RacemasterApiService, private _router: Router,
    private _sharingService: SharingService<Context>) {
  }

  ngOnInit() {
    this.appContext = this._sharingService.getMessage();

    this._apiService.getAll<Product>("Products").subscribe(
      (data: CollectionResponse<Product>) => { this.appContext.products = data.collection },
      (err) => { alert(err.message) },
      () => {
        this.appContext.products = this.appContext.products.sort((a, b) => a.displayOrder - b.displayOrder);
        this.appContext.products.forEach(p => p.subscriptions = p.subscriptions.sort((a, b) => a.displayOrder - b.displayOrder));
        this._sharingService.setMessage(this.appContext);
      }
    )



  }

  Refresh() {
    window.location.reload();
  }

  smartAnytime() {
    this._router.navigate(['anytime']);
  }

  onLearnMore(productId: string) {
    if (productId === "C723BA71-A776-43C2-B6D9-5A9044476077") { this._router.navigate(['ratings']); }
    else if (productId === "A98AA626-0193-41DF-AAE9-FD2408A8074F") { this._router.navigate(['select']); }
    else if (productId === "4B89B322-CDBE-4952-8BF0-A9AADE079B8F") { this._router.navigate(['plus']); }
    else if (productId === "542B0611-E8C8-46E4-B092-DF7A321F1D3B") { this._router.navigate(['smartodds']); }
    else if (productId === "00CE0D37-472C-4C32-B85F-5841AD5674E7") { this._router.navigate(['smartselect']); }
    else if (productId === "FEDBC6A3-25EF-4D39-B012-D2B727108632") { this._router.navigate(['smartplus']); }
    else if (productId === "FEDBC6A3-25EF-4D39-B012-D2B727108632") { this._router.navigate(['smartplus']); }
    else if (productId === "0C1E52F6-DFC6-4A2D-B2BE-507204C137D5") { this._router.navigate(['smartprem']); }




  }
  home() {
    this._router.navigate(['home']);
  }


}
