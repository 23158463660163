import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';

@Component({
  selector: 'app-racemaster-plus-perf',
  templateUrl: './racemaster-plus-perf.component.html',
  styleUrls: ['./racemaster-plus-perf.component.css']
})
export class RacemasterPlusPerfComponent implements OnInit {

  constructor(private modalService : ModalService) { }

  ngOnInit() {
  }
  public close() {
    this.modalService.destroy();
  }
  subOptionsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSuboptionsComponent, inputs, {});
  }
  subOptions() {
    this.close();
    this.subOptionsModal();
  }

}
