import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubInfoComponent } from './sub-info/sub-info.component';
import { SubPurchaseComponent } from './sub-purchase/sub-purchase.component';
import { AllServersComponent } from './all-servers/all-servers.component';
import { AllChannelsComponent } from './all-channels/all-channels.component';
import { AllProductSubscriptionsComponent } from './all-product-subscriptions/all-product-subscriptions.component';
import { AllAdminPagesComponent } from './all-admin-pages/all-admin-pages.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { RacemasterSuboptionsComponent } from './racemaster-suboptions/racemaster-suboptions.component';
import { SmartoddsPlusComponent } from './smartodds-plus/smartodds-plus.component';
import { RacemasterRatingsComponent } from './racemaster-ratings/racemaster-ratings.component';
import { RacemasterSelectComponent } from './racemaster-select/racemaster-select.component';
import { RacemasterPlusComponent } from './racemaster-plus/racemaster-plus.component';
import { SmartoddsSubsComponent } from './smartodds-subs/smartodds-subs.component';
import { SmartoddsSelectSubsComponent } from './smartodds-select-subs/smartodds-select-subs.component';
import { EmailComponent } from './email/email.component';
import { EnterPasswordComponent } from './enter-password/enter-password.component';
import { AnytimeComponent } from './anytime/anytime.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { RacemasterPlusPerfComponent } from './racemaster-plus-perf/racemaster-plus-perf.component';
import { SubPaymentComponent } from './sub-payment/sub-payment.component';
import { AnytimeWindowsComponent } from './anytime-windows/anytime-windows.component';
import { AboutComponent } from './about/about.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { SmartoddsHomeComponent } from './smartodds-home/smartodds-home.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  { path: 'subinfo', component: SubInfoComponent },
  { path: 'subpurchase', component: SubPurchaseComponent },
  { path: 'allservers', component: AllServersComponent },
  { path: 'allchannels', component: AllChannelsComponent },
  { path: 'allproductsub', component: AllProductSubscriptionsComponent },
  { path: 'alladminpages', component: AllAdminPagesComponent },
  { path: 'forgottenpass', component: ForgottenPasswordComponent },
  { path: 'smartoddsplus', component: SmartoddsPlusComponent },
  { path: 'home', component: SmartoddsHomeComponent },
  { path: 'plus', component: RacemasterPlusComponent },
  { path: 'suboptions', component: RacemasterSuboptionsComponent },
  { path: 'ratings', component: RacemasterRatingsComponent },
  { path: 'select', component: RacemasterSelectComponent },
  { path: 'smartodds', component: SmartoddsSubsComponent },
  { path: 'smartselect', component: SmartoddsSelectSubsComponent },
  { path: 'smartplus', component: SmartoddsPlusComponent },
  { path: 'email', component: EmailComponent },
  { path: 'enterpassword', component: EnterPasswordComponent },
  { path: 'subinfo', component: SubInfoComponent },
  { path: 'anytime', component: AnytimeComponent },
  { path: 'analysis', component: AnalysisComponent },
  { path: 'plusperf', component: RacemasterPlusPerfComponent },
  { path: 'subpayment', component: SubPaymentComponent }, 
  { path: 'anytimewindows', component: AnytimeWindowsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'userguide', component: UserGuideComponent },
  { path: 'signin', component: SignInComponent },




  { path: '**', component: SmartoddsHomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
