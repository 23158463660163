import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';

@Component({
  selector: 'app-sub-payment',
  templateUrl: './sub-payment.component.html',
  styleUrls: ['./sub-payment.component.css']
})
export class SubPaymentComponent implements OnInit {

  constructor(private modalService : ModalService) { }

  ngOnInit() {
  }
  public close() {
    this.modalService.destroy();
  }

emailModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EmailComponent, inputs, {});
  }
  Email() {
    this.emailModal();
  }


}
