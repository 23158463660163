import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Context, context } from '../models/context';
import { CookieService } from 'ngx-cookie-service';
import { spawnSync } from 'child_process';
import { User } from '../models/user';
import { SharingService } from '../Services/sharing-service';


@Component({
  selector: 'app-smartodds-home',
  templateUrl: './smartodds-home.component.html',
  styleUrls: ['./smartodds-home.component.css']
})
export class SmartoddsHomeComponent implements OnInit {
  isLoggedIn = false;
  isAdmin = false;
  userName: string;
  appContext: Context;
  constructor(
    private _router: Router, private _sharingService: SharingService<Context>, private _cookieService: CookieService
  ) {
    //we start the site using sharingservice to get context instance through getmessage instead of creating a new object on start everytime
    this.appContext = _sharingService.getMessage();

    if (this.appContext == null){
      _sharingService.setMessage(context);
      this.appContext = _sharingService.getMessage();
    }

    if (this.appContext.activeUser != null) {
      this.isLoggedIn = true;
      this.userName = this.appContext.activeUser.userName;

      let userModeClaim = this.appContext.activeUser.userClaims.find(c => c.claimName == "UserMode");

      if (userModeClaim != null) {
        if (userModeClaim.claimValue == "Administrator") {
          this.isAdmin = true;
        }
      }
    }
  }

  ngOnInit() {
  }

  adminPages() {
    this._router.navigate(["alladminpages"]);

  }

  signIn() {
    this._router.navigate(["signin"]);
  }


  email() {
    this._router.navigate(["email"]);
  }

  About() {
    this._router.navigate(['about']);
  }
 
  smartOddsSubOptions() {
    this._router.navigate(['suboptions']);
  }

  smartAnytime() {
    this._router.navigate(['anytime']);
  }
 
  smartOddsUserGuide() {
    this._router.navigate(['userguide']);
  }

  Refresh() {
    window.location.reload();
  }

  signOut() {
    this.appContext.activeUser = null;
  //   //after using appcontext in any component we must setmessage to save the updates
    this._sharingService.setMessage(this.appContext);

    this.Refresh();
   }
}