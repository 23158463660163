import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {

  constructor(private modalService: ModalService,
    private _router : Router) { }

  ngOnInit() {
  }
  
  home() { 
    this._router.navigate(['home'])
  }

  Email() {

    this._router.navigate(['email']);
    }


}
