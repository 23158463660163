import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-bootstrap/carousel'; 
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; 
import { RacemasterSelectComponent } from './racemaster-select/racemaster-select.component';
import { DomService } from './Services/dom.service';
import { ModalService } from './Services/modal.service';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { EmailComponent } from './email/email.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { EnterPasswordComponent } from './enter-password/enter-password.component';
import { RacemasterRatingsComponent } from './racemaster-ratings/racemaster-ratings.component';
import { RacemasterPlusComponent } from './racemaster-plus/racemaster-plus.component';
import { RacemasterPlusPerfComponent } from './racemaster-plus-perf/racemaster-plus-perf.component';
import { SmartoddsSubsComponent } from './smartodds-subs/smartodds-subs.component';
import { SmartoddsSelectSubsComponent } from './smartodds-select-subs/smartodds-select-subs.component';
import { RacemasterSuboptionsComponent } from './racemaster-suboptions/racemaster-suboptions.component';
import { SubInfoComponent } from './sub-info/sub-info.component';
import { SubPurchaseComponent } from './sub-purchase/sub-purchase.component';
import { SubPaymentComponent } from './sub-payment/sub-payment.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AnytimeComponent } from './anytime/anytime.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AboutComponent } from './about/about.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AnytimeWindowsComponent } from './anytime-windows/anytime-windows.component';
import { RacemasterApiService } from './Services/racemaster-api.service';
import { LocationService } from './Services/location.service';
import { SharingService } from './Services/sharing-service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { AllServersComponent } from './all-servers/all-servers.component';
import { AllChannelsComponent } from './all-channels/all-channels.component';
import { AllProductSubscriptionsComponent } from './all-product-subscriptions/all-product-subscriptions.component';
import { AllAdminPagesComponent } from './all-admin-pages/all-admin-pages.component';
import { SmartoddsPlusComponent } from './smartodds-plus/smartodds-plus.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { SmartoddsHomeComponent } from './smartodds-home/smartodds-home.component';
import { SignInComponent } from './sign-in/sign-in.component';


@NgModule({
  declarations: [
    AppComponent,
    RacemasterSelectComponent,
    EmailComponent,
    ForgottenPasswordComponent,
    EnterPasswordComponent,
    RacemasterRatingsComponent,
    RacemasterPlusComponent,
    RacemasterPlusPerfComponent,
    SmartoddsSubsComponent,
    SmartoddsSelectSubsComponent,
    RacemasterSuboptionsComponent,
    SmartoddsPlusComponent,
    SubInfoComponent,
    SubPurchaseComponent,
    SubPaymentComponent,
    AnalysisComponent,
    AnytimeComponent,
    ResetPasswordComponent,
    AboutComponent,
    UserGuideComponent,
    AnytimeWindowsComponent,
    AllServersComponent,
    AllChannelsComponent,
    AllProductSubscriptionsComponent,
    AllAdminPagesComponent,
    SmartoddsHomeComponent,
    SignInComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    NgbModule,
    CarouselModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    HttpClientModule,
    NgxPayPalModule
  ],
  entryComponents: [
    RacemasterSelectComponent, EmailComponent, ForgottenPasswordComponent, EnterPasswordComponent, RacemasterSelectComponent, RacemasterRatingsComponent, RacemasterPlusComponent, RacemasterPlusPerfComponent, SmartoddsSelectSubsComponent, SmartoddsSubsComponent, RacemasterRatingsComponent, RacemasterSuboptionsComponent, SmartoddsPlusComponent, SubInfoComponent, SubPurchaseComponent, SubPaymentComponent,
    AnalysisComponent, AnytimeComponent, ResetPasswordComponent, AboutComponent, UserGuideComponent, AnytimeWindowsComponent
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, DomService, ModalService, SharingService, RacemasterApiService, LocationService, CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
