import { LoginResponse } from './login-response';
import { User } from './user';
import { LoginRequest } from './login-request';
import { Product } from './product';

export class Context {
    constructor() {
        this.loginImageResource = "assets/Login Register.png";
        this.LoginRequest = new LoginRequest();
        this.registerRequest = new User();
        this.cloudStorageUrl = "racemaster-135b8.appspot.com";
        this.productModel = new Product();
        this.products = new Array<Product>();
    }
    // sign in and unlocked imgs
    public loginImageResource: string;
    // to send signed in email to enter pass component
    public LoginRequest: LoginRequest;
    // to send registered data from page1 and register page 2
    public registerRequest: User;
    // we use login response as it has the needed tokens requested by API whenever user logs in
    public loginInfo: LoginResponse;
    public isMobile : boolean;
    public activeUser: User;
    public productModel: Product;
    public products: Array<Product>;
    public productId : string;
    public subscriptionId : string; 
    public readonly cloudStorageUrl: string;
}
// exporting const context so that a new instance wouldn't be created everytime
export const context = new Context();
