
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';
import { User } from '../models/user';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { LocationService } from '../Services/location.service';
import { Country } from '../models/country';
import { CollectionResponse } from '../models/collection-response';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { SingleResponse } from '../models/single-response';

@Component({
  selector: 'app-sub-info',
  templateUrl: './sub-info.component.html',
  styleUrls: ['./sub-info.component.css']
})
export class SubInfoComponent implements OnInit {
  registerForm: FormGroup;
  appContext: Context;
  emptyInputs = false;
  allCountries = new CollectionResponse<Country>();
  registerAlertVisible = false;
  selectedCountries: string[];
  priorityCountries: Country[];
  constructor(
    @Inject(DOCUMENT) document,
    fb: FormBuilder,
    private _router: Router,
    private _racemasterApiService: RacemasterApiService,
    private modalService: ModalService,
    private _sharedService: SharingService<Context>,
    private _locationService: LocationService) {
    this.registerForm = fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      phonenumber: ["", Validators.required],
      cityortown: ["", Validators.required],
      country: ["", Validators.required],
      promocode: ["", Validators.nullValidator],
    })
    this.priorityCountries = new Array<Country>();
    this.priorityCountries.push(
      { "name": 'Australia', "order": 1, "callingCodes": [] },
      { "name": 'New Zealand', "order": 2, "callingCodes": [] },
      { "name": 'Hong Kong', "order": 3, "callingCodes": [] },
      { "name": 'Malaysia', "order": 4, "callingCodes": [] },
      { "name": 'Papua New Guinea', "order": 5, "callingCodes": [] },
      { "name": 'Singapore', "order": 6, "callingCodes": [] },
      { "name": 'Canada', "order": 7, "callingCodes": [] },
      { "name": 'Ireland', "order": 8, "callingCodes": [] },
      { "name": 'United States', "order": 9, "callingCodes": [] },
      { "name": 'United Kingdom', "order": 10, "callingCodes": [] }

    );

    this.selectedCountries = new Array<string>();
    this.selectedCountries.push('American Samoa', 'Austria', 'Bahamas', 'Belgium', 'China', 'Christmas Island', 'Cook Islands', 'Denmark', 'Egypt', 'Fiji', 'Finland', 'France', 'Germany', 'Gibraltar', 'Greece', 'Greenland', 'Hungary', 'Iceland', 'Indonesia', 'Isle of Man', 'Israel', 'Italy', 'Japan', 'Jersey', 'Korea', 'Lebanon', 'Liechtenstein', 'Luxembourg', 'Maldives', 'Malta', 'Mexico', 'Monaco', 'Nauru', 'Netherlands', 'New Caledonia', 'Narfolk Island', 'Norway', 'Philippines', 'Poland', 'Samoa', 'South Africa', 'Spain', 'Sweden', 'Switzerland', 'Taiwan', 'Thailand', 'Tonga', 'Virgin Islands', 'Vanuatu');

  }
  ngOnInit() {
    this._locationService.getLocations().subscribe(
      (data: Country[]) => {
        let pc = data.filter(pc => this.priorityCountries.find(c => c.name === pc.name) != null);

        pc.forEach(c => c.order = this.priorityCountries.find(pc => pc.name == c.name).order);
        pc = pc.sort((a, b) => a.order - b.order);
        this.allCountries.collection = pc.concat(
          data.filter(c => this.selectedCountries.indexOf(c.name) != -1).sort((a, b) => a.name.localeCompare(b.name))
        );
      },
      (err) => { alert(err.message); });

      this.appContext = this._sharedService.getMessage();
  }

  home() {
    this._router.navigate(["home"]);
  }
  email() {
    this._router.navigate(["email"]);
  }
  onRegister() {
    this.registerAlertVisible = true;
    var firstName = this.registerForm.controls["firstname"].value;
    var lastName = this.registerForm.controls["lastname"].value;
    var phoneNumber = this.registerForm.controls["phonenumber"].value.toString();
    // var cityOrTown = this.registerForm.controls["cityortown"].value;
    var Country = this.registerForm.controls["country"].value;
    if (firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0 || Country.length === 0) {
      this.emptyInputs = true;
    }
    else {
      this.appContext.registerRequest.firstName = firstName;
      this.appContext.registerRequest.lastName = lastName;
      this.appContext.registerRequest.phoneNumber = phoneNumber;
      this.appContext.registerRequest.location = Country;
      this._racemasterApiService.post<User>(this.appContext.registerRequest, "Users").subscribe(
        (data: SingleResponse) => { },
        (err) => { alert(err.message); },
        () => {
          this.registerAlertVisible = false;
          alert("Thank you for registering with Racemaster.\nAn email has been sent to " + this.appContext.registerRequest.email + " with a verification link. Please use the link to verify your email.")
          this.signIn();
        }
      );

    }


  }


  signIn() {
    this._router.navigate(['home']);
  }

  Email() {
    this._router.navigate(['email']);
  }

}
