import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { HttpClient } from '@angular/common/http';
import { Email } from '../models/email';
import { RacemasterApiService } from '../Services/racemaster-api.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  form: FormGroup;
  emptyInputs = false;
  constructor(fb: FormBuilder, private _router: Router, private modalService: ModalService, private _service: RacemasterApiService,
    private _http: HttpClient) {
    this.form = fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.required],
      phoneno: ["", Validators.required],
      comment: ["", Validators.required]
    })
  }

  ngOnInit() {
  }
  displayHome() {
  }
  displaySubOptions() {
    this._router.navigate(["suboptions"]);
  }
  onSubmit() {
    var emailModel = new Email();
    emailModel.firstName = this.form.controls["firstname"].value;
    emailModel.lastName = this.form.controls["lastname"].value;
    emailModel.email = this.form.controls["email"].value;
    emailModel.comment = this.form.controls["comment"].value;
    if (emailModel.firstName.length == 0 || emailModel.lastName.length == 0 || emailModel.email.length == 0 || emailModel.comment.length == 0 ) {
      alert("Please fill in the empty fields.")
      return;
    }
    this.form.reset();
    this._service.feedback(emailModel,"Users/feedback").subscribe(
      (data) => { },
      (err) => { alert(err.message); },
      () => {
        alert("Thank you! your feedback has been successully sent.")
        this._router.navigate(["home"]);
      }
    )
  }
  initLoginModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EmailComponent, inputs, {});
  }

  subOptionsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSuboptionsComponent, inputs, {});
  }

  home() {
    this._router.navigate(['home']);
  }
  subOptions() {
    this._router.navigate(['suboptions']);
  }
}
